import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';
// import Home from '../views/student/enroll/index.vue';

Vue.use(VueRouter);

const routes = [
    // dashboard
    { 
        path: '/', name: 'Home', component: Home,
        meta: { layout: true, login: true }
    },

    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue'),
        meta: { layout: true, login: true }
    }, 
    
    {
        path: '/student/enroll',
        name: 'enroll',
        component: () => import('../views/student/enroll/index.vue'),
        meta: { layout: true, login: true }
    },

    {
        path: '/contract/list',
        name: 'contract',
        component: () => import('../views/contract/list.vue'),
        meta: { layout: true, login: true }
    },
    {
        path: '/contract/list2',
        name: 'contract',
        component: () => import('../views/contract/list2.vue'),
        meta: { layout: true, login: true }
    },

    {
        path: '/contract/progress',
        name: 'contract_progress',
        component: () => import('../views/contract/progress.vue'),
        meta: { layout: true, login: true }
    },


    {
        path: '/search/progress',
        name: 'progress',
        component: () => import('../views/search/progress/index.vue'),
        meta: { layout: true, login: true }
    },

    {
        path: '/pages/faq',
        name: 'faq',
        component: () => import('../views/pages/faq/index.vue'),
        meta: { layout: true, login: false }
    },

    {
        path: '/users/profile',
        name: 'profile',
        component: () => import('../views/users/profile/index.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/users/account-setting',
        name: 'profile',
        component: () => import('../views/users/profile/index.vue'),
        meta: { layout: true, login: true }
    },

    {
        path: '/auth/login-boxed',
        name: 'login',
        component: () => import('../views/auth/login-boxed/index.vue'),
        meta: { layout: false, login: false }
    }, {
        path: '/auth/register-boxed',
        name: 'register',
        component: () => import('../views/auth/register-boxed/index.vue'),
        meta: { layout: false, login: false }
    },

    {
        path: '/auth/login-cover',
        name: 'login',
        component: () => import('../views/auth/login-cover/index.vue'),
        meta: { layout: false, login: false }
    }, {
        path: '/auth/register-cover',
        name: 'register',
        component: () => import('../views/auth/register-cover/index.vue'),
        meta: { layout: false, login: false }
    },
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
