import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import { get, post } from './tools/request';
import { getCookie, setCookie, delCookie } from './tools/cookie';
import { localGet, localSet, localRemove, localClear } from './tools/storage';

Vue.prototype.$axios = axios;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$delCookie = delCookie;
Vue.prototype.$localGet  = localGet;
Vue.prototype.$localSet  = localSet;
Vue.prototype.$localClear  = localClear;
Vue.prototype.$localRemove = localRemove;

Vue.use(ElementUI);

//bootstrap vue
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

//Sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#4361ee',
    cancelButtonColor: '#e7515a'
};
Vue.use(VueSweetalert2, options);

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

//vue-i18n
import i18n from './components/i18n/i18n';

Vue.config.productionTip = false;

// set default settings
import appSetting from './components/plugins/appSetting/app-setting';
Vue.prototype.$appSetting = appSetting;
appSetting.init();

// beforeEach
router.beforeEach((to, from, next) => {
    if (!to.meta || !to.meta.layout) {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    // 使用钩子函数对路由进行权限跳转
    let user_token = localGet('user_token');
    // console.log("user_token:", user_token)
    if ((!user_token || user_token == null || user_token == '') && to.meta.login == true) {
        next('/auth/login-cover');
    } else {
        next();
    }
});

new Vue({
    router,
    store,
    i18n,
    // el: '#app',
    render: h => h(App)
}).$mount('#app');
