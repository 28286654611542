<template>
    <div class="layout-px-spacing todo-list">
        <portal to="breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>select model</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </portal>

        <div class="account-settings-container layout-top-spacing">
            <div class="section general-info">
                <div class="info">
                    <h6 class="">首页 - 系统模块</h6>
                    <div class="row">
                        <div class="col-lg-11 mx-auto">
                            <div class="row">
    
                                <div class="infobox-2 col-xl-3" style="background-image: url(https://cnjiuwei.com/static/images/hp2-col-2.jpg) ;background-size: cover ; background-color: rgba(0,0,0,0.5); ">
                                    <!-- <div class="info-icon"> -->
                                        <img src="http://ausheng-school.oss-cn-hangzhou.aliyuncs.com/2022-11-13/802ea66caea5397cd86b5b5d96d80b1b.jpeg" style="width: 80px; height: 80px;border-radius:50%; margin-bottom: 20px; display: inline-block;" alt="">
                                    <!-- </div> -->
                                    <h5 class="info-heading">中治九纬ELMAN-让大学教育再无国界</h5>
                                    <p class="info-text">Elman是一个使命驱动的组织，致力于改变传统教育，消除成本、地点和访问...</p>
                                    <a class="info-link" href="https://cnjiuwei.com" target="_blank">
                                        进入官网 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div>
                                <br>
                                <br>
                                
                                <div class="infobox-2 col-xl-3" style="background-image: url(https://cnjiuwei.com/static/images/hp2-col-1.jpg) ;background-size: cover ; background-color: rgba(0,0,0,0.5); ">
                                    <!-- <div class="info-icon"> -->
                                        <img src="https://cnjiuwei.com/static/images/insert-enroll.png" style="width: 80px; height: 80px;border-radius:50%; margin-bottom: 20px; display: inline-block;" alt="">
                                    <!-- </div> -->
                                    <h5 class="info-heading">海外学术认证学生信息填写</h5>
                                    <p class="info-text">我们不只是为你提供教育和经验，更让你在职业生涯中取得成功...</p>
                                    <a class="info-link" href="javascript:void(0)" @click="$router.push('/student/enroll')">
                                        报名信息填写 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div>
                                <br>
                                <br>

                                <!-- <div class="infobox-2 col-xl-3" >
                                    <div class="info-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                    </div>
                                    <h5 class="info-heading">联合国儿童教育基金会</h5>
                                    <a href="https://cnjiuwei.com/blog-detail.html?unique=31" target="_blank">
                                        <p class="info-text">Elman帮助联合国儿童基金会募捐 我们需要您的支持，以帮助建立每个孩子都有公平的学习机会...<br><br></p>
                                    </a>
                                    <a class="info-link" href="javascript:void(0)">
                                        正在开发中 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div> -->
                                <div class="infobox-2 col-xl-3" >
                                    <div class="info-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                    </div>
                                    <h5 class="info-heading">Layout Package</h5>
                                    <p class="info-text">Lorem ipsum dolor sit amet, labore et dolore magna aliqua.</p>
                                    <a class="info-link" href="javascript:void(0)" @click="$router.push('/contract/list')">
                                        签订合同 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    



                    <!-- <div class="row">
                        <div class="col-lg-11 mx-auto">
                            <div class="row">
                                <div class="infobox-2 col-xl-3" >
                                    <div class="info-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                    </div>
                                    <h5 class="info-heading">Layout Package</h5>
                                    <p class="info-text">Lorem ipsum dolor sit amet, labore et dolore magna aliqua.</p>
                                    <a class="info-link" href="javascript:void(0)">
                                        缴纳学费 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div>
                                <br>
                                <br>
                                
                                <div class="infobox-2 col-xl-3" >
                                    <div class="info-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                    </div>
                                    <h5 class="info-heading">Layout Package</h5>
                                    <p class="info-text">Lorem ipsum dolor sit amet, labore et dolore magna aliqua.</p>
                                    <a class="info-link" href="javascript:void(0)">
                                        进度查询 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div>
                                <br>
                                <br>

                                <div class="infobox-2 col-xl-3" >
                                    <div class="info-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                    </div>
                                    <h5 class="info-heading">Layout Package</h5>
                                    <p class="info-text">Lorem ipsum dolor sit amet, labore et dolore magna aliqua.</p>
                                    <a class="info-link" href="javascript:void(0)">
                                        账单记录 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                </div>
                                <br>
                                <br>
                                
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import '@/assets/sass/scrollspyNav.scss';
    import '@/assets/sass/users/account-setting.scss';
    import '@/assets/sass/elements/infobox.scss';

    export default {
        metaInfo: { title: '首页' },
        data() {
            return {
                selected: null,
                selected_file: null,
                ddl_1: '20',
                ddl_2: 'Jan',
                ddl_3: '1989',
                ddl_4: 'May',
                ddl_5: '2009',
                ddl_6: 'United States',
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                range_1: 25,
                range_2: 50,
                range_3: 70,
                range_4: 60
            };
        },
        mounted() {},
        methods: {
            change_file(event) {
                this.selected_file = URL.createObjectURL(event.target.files[0]);
            }
        }
    };
</script>
