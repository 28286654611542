//封装请求的方法,
export function getUrlRules(url) {
    var url_rules = [
        '/accredit/login-phone-code.html',
        '/accredit/login-phone-password.html',
        '/accredit/register-phone-code.html',
        '/accredit/register-email-code.html',
        '/accredit/send-forget-code.html',
        '/accredit/reset-password.html',

        '/telephone-msm/send-login-code.html',
        '/telephone-msm/send-register-code.html',
        '/telephone-msm/send-forgot-code.html',

        '/email-msm/send-login-code.html',
        '/email-msm/send-register-code.html',
        '/email-msm/send-forgot-code.html',
    ];
    for (var i in url_rules) {
        if (url_rules[i] == url) {
            return true;
        }
    }
    return false;
}

export function get(url, params = {}, responseType = '', contentType = 'application/json;charset=UTF-8') {
    if (this.$localGet('user_token') == null && getUrlRules(url) == false) {
        this.$message({ message: '身份信息过期，需要您重新登录', type: 'warning' });
        return false;
    }

    var res_headers = {};
    if (this.$localGet('user_token')) {
        res_headers['Authorization'] = `Bearer ${this.$localGet('user_token')}`;
    }
    res_headers['Content-Type'] = contentType;

    url = '/api' + url;
    for (var i in params) {
        if (params[i] == "" && params[i] !== 0) {
            delete params[i];
        }
    }
    
    return new Promise((resolve, reject) => {
        this.$axios.get(url, {
            headers: res_headers,
            params: params,
            responseType: responseType,
            timeout: 30 * 1000
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            if (err.response.data.code === 999) {
                this.$message({ message: '身份信息过期，需要您重新登录', type: 'warning' });
                this.$localRemove('user_token');
                this.$router.push('/auth/login-cover');
            } else {
                if (!err.response.data) {
                    this.$message({ message: '服务器异常', type: 'error' });
                    reject(err)
                } else {
                    reject(err.response.data)
                }
            }
        })
    })
}

export function post(url, params = {}, responseType = '', contentType = 'application/json;charset=UTF-8') {
    if (this.$localGet('user_token') == null && getUrlRules(url) == false) {
        this.$message({ message: '身份信息过期，需要您重新登录', type: 'warning' });
        return false;
    }

    var res_headers = {};
    if (this.$localGet('user_token')) {
        res_headers['Authorization'] = `Bearer ${this.$localGet('user_token')}`;
    }
    res_headers['Content-Type'] = contentType;

    // var res_headers = {};
    // let user_token = this.$localGet('user_token')
    // if (user_token) {
    //     res_headers['Authorization'] = `Bearer ${user_token}`;
    // }
    
    // if (url.indexOf('uploadFiles') >= 0) {
    //     res_headers['Content-Type'] = 'multipart/form-data';
    // } else {
    //     res_headers['Content-Type'] = 'application/json;charset=UTF-8';
    // }

    for (var i in params) {
        if (params[i] == "" && params[i] !== 0) {
            delete params[i];
        }
    }

    url = '/api' + url;

    return new Promise((resolve, reject) => {
        this.$axios.post(url, params, {
            headers: res_headers,
            responseType: responseType,
            timeout: 120 * 1000
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            if (err.response.data.code === 999) {
                this.$message({ message: '身份信息过期，需要您重新登录', type: 'warning' });
                this.$localRemove('user_token');
                this.$router.push('/auth/login-cover');
            } else {
                if (!err.response.data) {
                    this.$message({ message: '服务器异常', type: 'error' });
                    reject(err)
                } else {
                    reject(err.response.data)
                }
            }
        })
    })
}
